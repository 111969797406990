import promoModel from '@/models/promo';

export default {
  namespaced: true,
  state: () => ({
    units: [],
    isPromoCallbackRequested: false,
  }),
  mutations: {
    'set:promo:units': function (state, units) {
      state.units = units;
    },
    'set:promo:is:promo-callback-requested': function (state, bool) {
      state.isPromoCallbackRequested = bool;
    },
  },
  actions: {
    async getPromoUnits({ commit }) {
      const resp = await promoModel.getSuggestions();
      if (resp.ok) {
        const updatedUnits = resp.data?.lots?.map((unit) => ({
          unitName: unit.name,
          unitId: unit.unit_id,
          imageArray: Array.isArray(unit.photo_url) ? unit.photo_url : [unit.photo_url],
          promo: unit.promo || false,
          guestCount: unit.capacity,
          areaCount: unit.area,
          part: unit.part,
          labelsObject: {
            location: {
              text: unit.location,
              color: '#ECFAFF',
            },
            unitType: {
              text: unit.activeType,
              color: '#E9F5F0',
            },
            unitState: {
              text: unit.stage,
              color: '#FFF4DE',
            },
          },
          launchDate: unit.launchDate,
          profitability: unit.profit,
          defaultPrice: unit.price || null,
          investorId: unit.investor_id,
        }));
        commit('set:promo:units', updatedUnits);
      }
    },
    async requestPromoCallback({ commit }, phone) {
      const resp = await promoModel.requestCallback({ phone });

      if (resp.ok && resp.data.success) {
        commit('set:promo:is:promo-callback-requested', true);
      }
    },
  },
};
